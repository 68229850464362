// extracted by mini-css-extract-plugin
export var topPage = "about-module--topPage--1YGb_";
export var banInn = "about-module--banInn--2rhb1";
export var volBtnOuter = "about-module--volBtnOuter--2vxrk";
export var volBtn = "about-module--volBtn--37lO4";
export var banRightOut = "about-module--banRightOut--3DG97";
export var banRight = "about-module--banRight--K0zrj";
export var misvis = "about-module--misvis--1N8K8";
export var misvisCont = "about-module--misvisCont--2fPNx";
export var misvisInn = "about-module--misvisInn--1hgxy";
export var misvisL = "about-module--misvisL--12qtZ";
export var misvisR = "about-module--misvisR--37vQ5";
export var coreHeading = "about-module--coreHeading--3baxo";
export var coreOuter = "about-module--coreOuter--36PsD";
export var coreValue = "about-module--coreValue--1x8Un";
export var opsOuter = "about-module--opsOuter--1gF3n";
export var opsTitle = "about-module--opsTitle--1b4gx";
export var opOuter = "about-module--opOuter--RQT_n";
export var iconsOp = "about-module--iconsOp--17Gp_";
export var underOps = "about-module--underOps--1QsH3";
export var historyBanOut = "about-module--historyBanOut--37IAR";
export var historyBan = "about-module--historyBan--QuRTK";
export var histImg = "about-module--histImg--3cnoA";
export var founderCont = "about-module--founderCont--3-Fql";
export var founder = "about-module--founder--3CNHJ";
export var founderInn = "about-module--founderInn--1frjN";
export var founderCapt = "about-module--founderCapt--3Yetz";
export var teamTop = "about-module--teamTop--27LRj";
export var teamTopPar = "about-module--teamTopPar--3_WsB";
export var contributors = "about-module--contributors--3n3K-";
export var contributorsOut = "about-module--contributorsOut--2sO1N";
export var studentBtm = "about-module--studentBtm--2pCX3";
export var alumniMid = "about-module--alumniMid--19Hwf";
export var alumniMidInn = "about-module--alumniMidInn--wCNK2";
export var alumni = "about-module--alumni--2eEW2";
export var bottomLine = "about-module--bottomLine--11L5-";
export var alumniITop = "about-module--alumniITop--n_pil";
export var careersBtm = "about-module--careersBtm--354Oz";
export var careerBanOut = "about-module--careerBanOut--bp6NY";
export var careerBan = "about-module--careerBan--RYZX0";
export var careersFoot = "about-module--careersFoot--2pbPH";
export var carImg = "about-module--carImg--3rRGa";
export var careersInn = "about-module--careersInn--DMhnN";
export var career = "about-module--career--3G0R_";
export var careerIcons = "about-module--careerIcons--2hgFd";
export var volunteerBanOut = "about-module--volunteerBanOut--15en2";
export var volunterBan = "about-module--volunterBan--CS0-e";
export var volunteerMid = "about-module--volunteerMid--wZdK7";
export var volBtm = "about-module--volBtm--v6zIw";
export var volImg = "about-module--volImg--gBhyp";
export var partnerOp = "about-module--partnerOp--3Eo35";
export var partnersTopSect = "about-module--partnersTopSect--3bNSs";
export var partnersTopList = "about-module--partnersTopList--28wsZ";
export var partnersOpBtm = "about-module--partnersOpBtm--30ZDL";
export var partnersBttm = "about-module--partnersBttm--1_zJR";